
export const allMainnet = {
    id: 0x9f2a4,
    name: 'ALL Mainnet',
    network: 'allMainnet',
    nativeCurrency: {
        name: 'ALL',
        symbol: 'ALL',
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http: ['https://mainnet-rpc.alltra.global']
        },
        public: { http: ['https://mainnet-rpc.alltra.global'] },
    },
    blockExplorers: {
        default: { name: 'ALL Mainnet Explorer', url: 'https://alltra.global' }
    }
};

export const allTestnet = {
    id: 0xf3c13, //998419
    name: 'ALL Testnet',
    network: 'allTestnet',
    nativeCurrency: {
        name: 'ALLTST',
        symbol: 'ALLTST',
        decimals: 18,
    },
    rpcUrls: {
        default: { http: ['https://testnet-rpc.alltra.global'] },
        public: { http: ['https://testnet-rpc.alltra.global'] }
    },
    blockExplorers: {
        default: { name: 'ALL Testnet Explorer', url: 'https://testnet-explorer.alltra.global' }
    }
};
