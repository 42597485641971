import './price-ticker.scss';

import { quoter } from '/apps/common/quoter.js';
import { overlay } from '../overlay.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import { parseDecimal } from '../common.js';

let switchValue = false;
let tickerShown = false;

let selected = 'USD';
// false = USD
// true = AUD

//TODO USD
//TODO ALL
//TODO HYBX
//TODO XAU
//TODO 11::11
let rates = quoter.currentQuote ? quoteToRates(quoter.currentQuote) : null;

quoter.subscribe(quote => {
    rates = quoteToRates(quote);
    if(tickerShown) refreshTicker();
});

// {
//     "HYBX_ETH": 7e-8,
//     "ALL_ETH": 9e-8,
//     "HYBX_ALL": 0.7777777777777778,
//     "ALL_HYBX": 1.2857142857142856,
//     "ETH_GAS_COST": 0.0003213341618618208,
//     "ALL_GAS_COST": 0,
//     "ETH_XAU": 0.80981914,
//     "ETH_USD": 1568.895,
//     "ETH_AUD": 2460.915228,
//     "XAU_USD": 1937.3399843327982,
//     "timestamp": 1697602538
//   }
function quoteToRates(quote) {
    // base to rate

    const { ETH_USD, ETH_AUD, XAU_USD, ALL_ETH, HYBX_ETH } = quote;

    const result = {
        'USD': [
            { symbol: 'AUD', rate: 1 / ETH_USD * ETH_AUD },
            { symbol: 'XAU', rate: 1 / XAU_USD },
            // { symbol: 'ALL', rate:  1 / ETH_USD * 1 / ALL_ETH },
            { symbol: '11::11', rate: 1 / XAU_USD * 1000 },
            // { symbol: 'HYBX', rate: 1 / ETH_USD * 1 / HYBX_ETH }
        ],
        'AUD': [
            { symbol: 'USD', rate: 1 / ETH_AUD * ETH_USD },
            { symbol: 'XAU', rate: 1 / ETH_AUD * ETH_USD / XAU_USD },
            // { symbol: 'ALL', rate: 1 / ETH_AUD * 1 / ALL_ETH },
            { symbol: '11::11', rate: 1 / ETH_AUD * ETH_USD / XAU_USD * 1000 },
            // { symbol: 'HYBX', rate: 1 / ETH_AUD * 1 / HYBX_ETH }
        ],
        'XAU': [
            { symbol: 'USD', rate: XAU_USD },
            { symbol: 'AUD', rate: XAU_USD * 1 / ETH_USD * ETH_AUD },
            { symbol: '11::11', rate: 1000 }
        ]
    };

    return result;
};

export function PriceTickerButton() {
    return (
        <div className="price-ticker-button" onClick={showTicker}>
            <FontAwesomeIcon icon={ faChartLine } />
        </div>
    )
}

export function showTicker() {
    overlay.show(<PriceTicker />);
}

export function refreshTicker() {
    overlay.show(<PriceTicker />);
}

export function PriceTicker() {
    //TODO ALL
    //TODO HYBX
    //TODO GOLD

    // AUD => usd
    // USD => AUD

    const base = selected;
    const baseRates = rates[base];
    const decimals = baseRates.map(d => parseDecimal(d.rate, 4));

    decimals[0][1] = '$' + decimals[0][1];

    if(base !== 'XAU') {
        decimals[1][3] = decimals[1][3] + 'oz';
    } else {
        decimals[1][1] = '$' + decimals[1][1];
    }

    // const baseCol = [(<div>{selected === 'XAU' ? '1oz' : '$1'} <b>{base}</b></div>), '', ''];

    const elements = [];
    let j = 0;
    const rows = decimals.forEach((d, i) => {
        if(i===0) {
            const baseCurr = selected === 'XAU' ? '1oz' : '$1';
            elements.push(
                <div key={j++}>{baseCurr} <b>{base}</b></div>
            )
        } else {
            elements.push(<div key={j++}></div>);
        }

        elements.push(<div key={j++}>=</div>);
        elements.push(<div key={j++} className="numeral">{d[1]}</div>);
        elements.push(<div key={j++}>{d[2]}</div>);
        elements.push(<div key={j++} className="decimal">{d[3]}</div>);
        elements.push(<div key={j++}><b>{baseRates[i].symbol}</b></div>);

    })

    // const list = baseRates.map((r, i) => {
    //     console.log(r, parseDecimal(r.rate, 4))
    //     return (
    //         <div key={i}>
    //             1 {base} &rarr; {parseDecimal(r.rate, 4)} {r.symbol}
    //         </div>
    //     );
    // });

    const currencyButtons = ['USD', 'AUD', 'XAU'].map((base, i) => {
        return (
            <div key={i} onClick={setBase(base)} highlighted={String(selected === base)}><b>{base}</b></div>
        )
    })

    return (
        <div className="price-ticker">
            <h3>Current Rates</h3>
            <div className="exchange-rates">
                {elements}
            </div>
            <h3>Base Currency</h3>
            <div className="currency-selection">
                {currencyButtons}
            </div>
        </div>
    )

    function switchClicked() {
        switchValue = !switchValue;
        showTicker();
    }

    function setBase(base) {
        return () => {
            selected = base
            showTicker();
        }
    }
}

export function DappsSwitch({ value, onClick }) {

    value = value ? 'on' : 'off';
    
    return (
        <div className={`dapps-switch ${value}`} onClick={onClick}>
            <div className="right-side" />
            <div className="left-side" />
            <div className="circle" />
        </div>
    );
}
