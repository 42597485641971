
import promish from './promish.js';

const subs = new Set();

export const quoter = {
    currentQuotePromise: promish(),
    currentQuote: null, // for synchronous inspection
    subscribe(f) {
        subs.add(f);
    },

    unsubscribe(f) {
        subs.delete(f);
    }
};

quoter.currentQuotePromise.catch(() => null);

quoter.intervalId = setInterval(updatecurrentQuotePromise, 60000);
updatecurrentQuotePromise();

async function updatecurrentQuotePromise() {
    quoter.currentQuotePromise.reject();
    quoter.currentQuotePromise = promish();

    const request = new Request('https://f2hzv6aufluh22w3ifnmri4imu0vwwlm.lambda-url.ap-southeast-2.on.aws/');

    fetch(request)
        .then((result) => result.json())
        .then(json => {
            quoter.currentQuote = json;
            quoter.currentQuotePromise.resolve(json);

            // window.quote = JSON.parse(JSON.stringify(json));
            subs.forEach(sub => sub(json));
        })

}

window.quoter = quoter;
window.quote = quoter.currentQuote;
quoter.subscribe((quote) => window.quote = quote);
