

export function parseDecimal(num, dp) {
    const isPositive = num >= 0;

    num = Math.abs(num);

    const exp = Math.pow(10, dp);

    const head = Math.floor(Math.round(num * exp) / exp);
    const tail = Math.round(Math.round(num * exp) % exp) / exp;

    const arr = [isPositive ? '+' : '-', head.toString()];

    if (tail > 0) {
        arr.push('.', tail.toString().replace(/0*$/, '').replace(/^0./, ''));
    } else {
        arr.push('', '');
    }

    return arr;
}
