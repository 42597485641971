
import './dapps.scss';

import { Modal } from '/apps/common/wagmi-tools.js';
import { useState } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToolbox, faCoins, faDollarSign, faParachuteBox, faBridge, faArrowUpRightFromSquare, faBars,
    faCircleQuestion, faRightToBracket, faHouse, faCircleInfo, faChartLine, faWallet } from '@fortawesome/free-solid-svg-icons'

import { overlay } from './overlay.js';
import { notifier } from './notifier.js';
import { PriceTickerButton } from './price-ticker/price-ticker.js';

import { quoter } from '/apps/common/quoter.js';

import { getAccount, getNetwork, switchNetwork, readContract, writeContract, fetchBalance } from '@wagmi/core';
import { allMainnet } from '/apps/common/custom-chains.js';
import { mainnet } from '@wagmi/core/chains';
import { allHybxAddress, goldAddress } from '../apps/common/addresses.js';
// import { ERC20Abi } from '../apps/common/abis';
import { erc20ABI } from 'wagmi';

export function DappsHeader(props) {
    return (
        <div className="dapps-header">
            <DappsLogo />
            <DappsDropdown onClick={props.toggleMenu} />
            <div className="flex1">{props.children}</div>
            <PriceTickerButton />
            <DappsWalletButton />
            <DappsConnector />
            {/* <DappsLogin /> */}
        </div>
    );
}

export function DappsDropdown(props) {

    return (
        <div className="dapps-dropdown">
            
            <div className="dapps-button" onClick={props.onClick}>
                <FontAwesomeIcon icon={faBars} />&nbsp;Apps
            </div>
        </div>
    );
}

export function DappsMenu(props) {
    return (
        <div className="dapps-menu" open={props.open}>
            <HomeAppButton />
            <ImportToolsAppButton />
            <EthOnboardingAppButton />
            <FiatOnboardingAppButton />
            <AirdropAppButton />
            <HybxAllAppButton />
            <HybxBridgeAppButton />
            <MarketplaceLink />
            {/* help button should be last */}
            <HelpAppButton overlay={props.overlay} />
        </div>
    )
}

export function DappsMenuItem(props) {
    const { href } = props;
    return (
        <div className="dapps-menu-item">
            <a href={href}>{props.children}</a>
        </div>
    )
}

export function DappsDropdownItem(props) {
    return (
        <div className="dapps-dropdown-item">
            {props.children};
        </div>
    )
}

export function DappsLogo() {
    const href = /^\/devtest/.test(location.pathname)
        ? '/devtest/'
        : '/';
    return (
        <div className="dapps-logo">
            <a href={href}>
                <img className="dapps-logo-img" src="/assets/alltra-logo-trimmed.png"></img>
            </a>
        </div>
    );
}

export function DappsConnector() {
    return (
        <div className="dapps-connector">
            <Modal />
        </div>
    )
}

export function DappsLogin() {
    return (
        <div className="dapps-login">
            <div className="dapps-button">TODO login</div>
        </div>
    );
}

export function DappsFooter() {

    const aboutUsData = [
        { href: '/legal/index.html', text: 'Legal' },
        { href: 'https://alltra.world/contact-us/', text: 'Contact us' },
        { href: 'https://alltra.world/contact-us/', text: 'Join the team' },
    ];

    const linksData = [
        { href: 'https://alltra.world/register/', text: 'Become a member' },
        { href: 'https://staking.alltra.global/', text: 'Staking' },
        { href: 'https://discord.gg/mw6gNKfvh3', text: 'Community' },
    ];

    const generateFooterLinks = (d, i) => {
        return (
            <a key={i} href={d.href}>{d.text}</a>
        );
    }

    return (
        <div className="dapps-footer">
            {/* <DappsFooterMeta></DappsFooterMeta> */}
            <DappsFooterLinks title="About Us">
                {aboutUsData.map(generateFooterLinks)}
            </DappsFooterLinks>
            <DappsFooterLinks title="Links">
                {linksData.map(generateFooterLinks)}
            </DappsFooterLinks>
        </div>
    );
}

export function DappsFooterMeta() {
    return (
        <div className="dapps-footer-meta">TODO: meta information about site shown only on desktop</div>
    )
}

export function DappsFooterLinks(props) {
    return (
        <div className="dapps-footer-links">
            <h3>{props.title}</h3>
            <div>{props.children}</div>
        </div>
    )
}


export function DappsTemplate(props) {
    const { overlay } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };
    return (
        <div className="dapps-template">
            <DappsHeader toggleMenu={toggleMenu}></DappsHeader>
            <DappsMenu style={{display: menuOpen ? 'block' : 'none'}} open={menuOpen} overlay={overlay}></DappsMenu>
            <div className="dapps-template-body">
                <div className="flex1"></div>
                <DappsContent>{props.children}</DappsContent>
                <div className="flex2"></div>
            </div>
            <DappsFooter></DappsFooter>
        </div>
    );
}

export function DappsButton(params) {
    const { children, onClick, disabled } = params;
    const nestedParams = {
        onClick: disabled ? null : onClick,
        disabled
    };
    return (
        <div className="dapps-button" {...nestedParams}>
            {children}
        </div>
    )
}

export function DappsAppButton(props) {
    const { href, children, icon, className } = props;
    const prefix = /^\/devtest/.test(location.pathname)
        ? '/devtest'
        : '';
    return (
        <a className={`dapps-app-button ${className}`} href={prefix + href}>
            {icon && <FontAwesomeIcon icon={icon} /> || null}
            &nbsp;{children}
        </a>
    )
}

export function DappsAppLink(props) {
    const { href, children } = props;
    return (
        <DappsAppButton className="dapps-app-link" {...{href}} icon={faArrowUpRightFromSquare}>
            {children}
        </DappsAppButton>
    );
}

export function HelpAppButton(props) {
    const { overlay } = props;

    return (
        <div className="dapps-app-button" onClick={showHelp}>
            <FontAwesomeIcon icon={faCircleInfo} />
            &nbsp;Help
        </div>
    )

    function showHelp() {
        const arr = ['hello', 'world', 'foo', 'bar', 'baz'];

        const content = [
            <>Welcome to the ALLTRA App</>,
            <>Click on "<FontAwesomeIcon icon={faHouse}/> Home" to go back to the home page.</>,
            <>Click on "<FontAwesomeIcon icon={faBars}/> Apps" to open and close the menu bar.</>,
            <>Click on <Modal/> to connect your wallet to the App.</>,
            <>Click on "<FontAwesomeIcon icon={faWallet}/>" to view your assets on the Alltra Network</>,
            <>Click on "<FontAwesomeIcon icon={faChartLine}/>" to view the current trading prices of your assets.</>,
            <>Click on "<b>Help</b>" to view the information of the App you are currently using.</>,
            <>Scroll down to the bottom of the page to view some helpful links in the footer.</>,
            // <>Make sure to keep checking the ALLTRA HUB App on a regular basis, you might get a visit from the ALLTRA Golden Frog and receive free 11::11 coins.</>,
            <>New to ALLTRA? Click on "<FontAwesomeIcon icon={faToolbox}/> Imports" to get the ALLTRA Chain, 11::11, HYBX and ALL Coin in your wallet.</>
        ].map(f => <div className="help-content">{f}</div>);

        overlay.show(<DappsTapThrough content={content} />);
    }
}

export function HomeAppButton() {
    return (
        <DappsAppButton icon={faHouse} href="/">
            Home
        </DappsAppButton>
    )
}

export function ImportToolsAppButton() {
    return (
        <DappsAppButton icon={faToolbox} href="/apps/import-tools/index.html">
            Imports
        </DappsAppButton>
    )
}   

export function FiatOnboardingAppButton() {
    return (
        <DappsAppButton href="/apps/fiat-onboarding/index.html" icon={faDollarSign}>
            Buy with Fiat
        </DappsAppButton>
    )
}
export function EthOnboardingAppButton() {
    return (
        <DappsAppButton href="/apps/eth-onboarding/index.html" icon={faDollarSign}>
            Buy with Eth
        </DappsAppButton>
    )
}
export function AirdropAppButton() {
    return (
        <DappsAppButton href="/apps/airdrop/index.html" icon={faParachuteBox}>
            Airdrop
        </DappsAppButton>
    )
}
export function HybxAllAppButton() {
    return (
        <DappsAppButton href="/apps/hybx-all/index.html" icon={faBridge}>
            ALL Bridge
        </DappsAppButton>
    )
}
export function HybxBridgeAppButton() {
    return (
        <DappsAppButton href="/apps/hybx-bridge/index.html" icon={faBridge}>
            HYBX Bridge
        </DappsAppButton>
    )
}

export function DappsContent(props) {
    return (
        <div className="dapps-content">
            {props.children}
        </div>
    )
}

export function FiatLoginLink(props) {
    return (
        <DappsAppButton href="/apps/fiat-onboarding/index.html" icon={faRightToBracket}>
            Login
        </DappsAppButton>
    )
}

export function StakingLink(props) {
    return (
        <DappsAppLink href="https://staking.alltra.global" icon={faChartLine}>
            Staking
        </DappsAppLink>
    )
}

export function MarketplaceLink(props) {
    return (
        <DappsAppLink href="https://hyb-all.com">
            Marketplace
        </DappsAppLink>
    )
}

export function DappsQuoteVisualizer(props) {
    const { from, to, amount, rate, timestamp } = props;

    let msg = null;

    if(amount === 0) {
        msg = `Enter the amount of from you want to convert into ${to}`;
    } else if(amount < 0) {
        msg = `Amount must be positive`
    } else if(Number.isNaN(amount)) {
        msg = `You must enter a number`;
    } else { // amount > 0
        msg = `Exchange ${amount} ${from} for ${amount * rate} ${to}`;
    }

    return (
        <div className="dapps-quote-visualizer">
            <h4>Current Rate</h4>
            <div>1 {from} for {rate} {to}</div>
            {/* <div>at {new Date(timestamp * 1000).toUTCString()}</div> */}
            {/* <div>Estimated Transaction Value</div> */}
            <div>Exchange {amount} {from} for {amount * rate} {to}</div>
        </div>
    );
}

const uuid = () => Math.random();
export function DappsGasTaxVisualizer(props) {
    const { from, to, amount, rate, timestamp } = props;
    const { toGas, fromGas, gasFee, gasSymbol } = props;

    const total = (amount * toGas - gasFee) * fromGas;

    let msg = null;
    let gtv = null;
    if (amount === 0) {
        msg = `Enter an amount`
    } else if (total < 0) {
        msg = `You must transfer at least ${gasFee / toGas} ${from} to cover the gas fees of the transaction`;
    } else {
        // console.log({ amount, rate, total: amount * rate })
        const rows = [amount * rate, gasFee, total].map(n => parseDecimal(n, 4));

        rows[0][0] = '';
        rows[1][0] = '-';
        rows[2][0] = '=';

        if (total < 0) rows[2][1] = '-' + rows[2][1];

        ['Initial value:', 'Est. Gas:', 'Total:'].forEach((s, i) => rows[i].unshift(s));
        [to, gasSymbol, to].forEach((s, i) => rows[i].push(s));

        const cols = pivot(rows);
        const indexToClassName = [
            'gtv-description',
            'gtv-sign',
            'gtv-whole',
            'gtv-decimal',
            'gtv-fraction',
            'gtv-currency'

        ];
        
        gtv = (
            <div className="gtv-table">
                {cols.map((col, i) => (<div className={indexToClassName[i]} key={uuid()}>{col.map((cell, j) => <div key={uuid()}>{cell}</div>)}</div>))}
            </div>
        )
    }

    return (
        <div className="dapps-gas-tax-visualizer">
            <h4>Current Rate</h4>
            <div className="rate">{`1 ${from} for ${rate} ${to}`}</div>
            {/* <div>{`Generated At: ${new Date(timestamp * 1000).toUTCString()}`}</div> */}
            {msg}
            {gtv}
        </div>
    )


    function pivot(rows) {
        const cols = [];
        const numRows = rows.length;
        const numCols = rows[0].length;

        for (let c = 0; c < numCols; ++c) {
            const col = cols[c] = [];
            for (let r = 0; r < numRows; ++r) {
                cols[c][r] = rows[r][c];
            }
        }

        return cols;
    }

    function parseDecimal(num, dp) {
        const isPositive = num >= 0;

        num = Math.abs(num);

        const exp = Math.pow(10, dp);

        const head = Math.floor(Math.round(num * exp) / exp);
        const tail = Math.round(Math.round(num * exp) % exp) / exp;

        const arr = [isPositive ? '+' : '-', head.toString()];

        if (tail > 0) {
            arr.push('.', tail.toString().replace(/0*$/, '').replace(/^0./, ''));
        } else {
            arr.push('', '');
        }

        return arr;
    }
}

export function TransactionDetails(props) {
    const { chain, hash } = props;
    const { name, url } = chain.blockExplorers.default;
    const href = `${url}/tx/${hash}`;

    // ie
    //https://etherscan.io/tx/0x1fe843d4737eb72753cad055c7490372fc68eab18c1784dc39a5feda47e9cf78
    //https://alltra.global/tx/0x43528a42e55a0a1e82e5710cf1639c94c8c235239477ca01412d5b88969d5069

    return (
        <div className="transaction-details">
            <div className="flex-2"></div>
            <h2>Transaction sent</h2>
            <h4>
                View on <a href={href} target="_blank">{name}&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </h4>
            <div className="flex-2"></div>
        </div>
    );
}

export function FirstVisitRedirect(params) {
    return (
        <div className="first-visit-redirect">
            <h2>First time here?</h2>
            <p>Add Alltra Chain, 11::11 and HYBX to your wallet by clicking on</p>
            <ImportToolsAppButton />
            <h2>Don't have a wallet yet?</h2>
            <p>Click on one of these links to create one</p>
            <WalletLinks/>
            <br/>
            <h2>Want to see your assets?</h2>
            <div>Check what you own with the token viewer</div>
            <DappsWalletButton/>
        </div>
    )
}

export function WalletLinks() {
    return (
        <div className="wallet-links">
            <div>
                <a className="metamask-link" href="https://metamask.io/" target="_blank">
                    <MetamaskVerticalSvg/>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a className="trust-wallet-link" href="https://trustwallet.com/" target="_blank">
                    <TrustWalletVerticalSvg/>
                </a>
            </div>
        </div>
    )
}

export function TrustWalletVerticalSvg() {
    return (
        <svg className="trust-wallet-vertical-svg" viewBox="0 0 288 234" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.96 168.755H30V163.475H54.4V168.755H45.44V191.475H38.96V168.755Z" fill="#3375BB"/>
            <path d="M60.5378 172.795C61.2845 171.755 62.2845 170.968 63.5378 170.435C64.8178 169.901 66.2845 169.635 67.9378 169.635V175.395C67.2445 175.341 66.7778 175.315 66.5378 175.315C64.7511 175.315 63.3511 175.821 62.3378 176.835C61.3245 177.821 60.8178 179.315 60.8178 181.315V191.475H54.5778V169.955H60.5378V172.795Z" fill="#3375BB"/>
            <path d="M91.0844 169.955V191.475H85.1644V188.915C84.3377 189.848 83.351 190.568 82.2044 191.075C81.0577 191.555 79.8177 191.795 78.4844 191.795C75.6577 191.795 73.4177 190.981 71.7644 189.355C70.111 187.728 69.2844 185.315 69.2844 182.115V169.955H75.5244V181.195C75.5244 184.661 76.9777 186.395 79.8844 186.395C81.3777 186.395 82.5777 185.915 83.4844 184.955C84.391 183.968 84.8444 182.515 84.8444 180.595V169.955H91.0844Z" fill="#3375BB"/>
            <path d="M101.705 191.795C99.9186 191.795 98.172 191.581 96.4653 191.155C94.7586 190.701 93.3986 190.141 92.3853 189.475L94.4653 184.995C95.4253 185.608 96.5853 186.115 97.9453 186.515C99.3053 186.888 100.639 187.075 101.945 187.075C104.585 187.075 105.905 186.421 105.905 185.115C105.905 184.501 105.545 184.061 104.825 183.795C104.105 183.528 102.999 183.301 101.505 183.115C99.7453 182.848 98.292 182.541 97.1453 182.195C95.9986 181.848 94.9986 181.235 94.1453 180.355C93.3186 179.475 92.9053 178.221 92.9053 176.595C92.9053 175.235 93.292 174.035 94.0653 172.995C94.8653 171.928 96.012 171.101 97.5053 170.515C99.0253 169.928 100.812 169.635 102.865 169.635C104.385 169.635 105.892 169.808 107.385 170.155C108.905 170.475 110.159 170.928 111.145 171.515L109.065 175.955C107.172 174.888 105.105 174.355 102.865 174.355C101.532 174.355 100.532 174.541 99.8653 174.915C99.1986 175.288 98.8653 175.768 98.8653 176.355C98.8653 177.021 99.2253 177.488 99.9453 177.755C100.665 178.021 101.812 178.275 103.385 178.515C105.145 178.808 106.585 179.128 107.705 179.475C108.825 179.795 109.799 180.395 110.625 181.275C111.452 182.155 111.865 183.381 111.865 184.955C111.865 186.288 111.465 187.475 110.665 188.515C109.865 189.555 108.692 190.368 107.145 190.955C105.625 191.515 103.812 191.795 101.705 191.795Z" fill="#3375BB"/>
            <path d="M127.195 190.435C126.582 190.888 125.822 191.235 124.915 191.475C124.035 191.688 123.102 191.795 122.115 191.795C119.555 191.795 117.569 191.141 116.155 189.835C114.769 188.528 114.075 186.608 114.075 184.075V175.235H110.755V170.435H114.075V165.195H120.315V170.435H125.675V175.235H120.315V183.995C120.315 184.901 120.542 185.608 120.995 186.115C121.475 186.595 122.142 186.835 122.995 186.835C123.982 186.835 124.822 186.568 125.515 186.035L127.195 190.435Z" fill="#3375BB"/>
            <path d="M179.986 163.475L170.826 191.475H163.866L157.706 172.515L151.346 191.475H144.426L135.226 163.475H141.946L148.266 183.155L154.866 163.475H160.866L167.266 183.315L173.786 163.475H179.986Z" fill="#3375BB"/>
            <path d="M187.842 169.635C191.176 169.635 193.736 170.435 195.522 172.035C197.309 173.608 198.202 175.995 198.202 179.195V191.475H192.362V188.795C191.189 190.795 189.002 191.795 185.802 191.795C184.149 191.795 182.709 191.515 181.482 190.955C180.282 190.395 179.362 189.621 178.722 188.635C178.082 187.648 177.762 186.528 177.762 185.275C177.762 183.275 178.509 181.701 180.002 180.555C181.522 179.408 183.856 178.835 187.002 178.835H191.962C191.962 177.475 191.549 176.435 190.722 175.715C189.896 174.968 188.656 174.595 187.002 174.595C185.856 174.595 184.722 174.781 183.602 175.155C182.509 175.501 181.576 175.981 180.802 176.595L178.562 172.235C179.736 171.408 181.136 170.768 182.762 170.315C184.416 169.861 186.109 169.635 187.842 169.635ZM187.362 187.595C188.429 187.595 189.376 187.355 190.202 186.875C191.029 186.368 191.616 185.635 191.962 184.675V182.475H187.682C185.122 182.475 183.842 183.315 183.842 184.995C183.842 185.795 184.149 186.435 184.762 186.915C185.402 187.368 186.269 187.595 187.362 187.595Z" fill="#3375BB"/>
            <path d="M201.45 161.795H207.69V191.475H201.45V161.795Z" fill="#3375BB"/>
            <path d="M211.081 161.795H217.321V191.475H211.081V161.795Z" fill="#3375BB"/>
            <path d="M241.792 180.795C241.792 180.875 241.752 181.435 241.672 182.475H225.392C225.686 183.808 226.379 184.861 227.472 185.635C228.566 186.408 229.926 186.795 231.552 186.795C232.672 186.795 233.659 186.635 234.512 186.315C235.392 185.968 236.206 185.435 236.952 184.715L240.272 188.315C238.246 190.635 235.286 191.795 231.392 191.795C228.966 191.795 226.819 191.328 224.952 190.395C223.086 189.435 221.646 188.115 220.632 186.435C219.619 184.755 219.112 182.848 219.112 180.715C219.112 178.608 219.606 176.715 220.592 175.035C221.606 173.328 222.979 172.008 224.712 171.075C226.472 170.115 228.432 169.635 230.592 169.635C232.699 169.635 234.606 170.088 236.312 170.995C238.019 171.901 239.352 173.208 240.312 174.915C241.299 176.595 241.792 178.555 241.792 180.795ZM230.632 174.355C229.219 174.355 228.032 174.755 227.072 175.555C226.112 176.355 225.526 177.448 225.312 178.835H235.912C235.699 177.475 235.112 176.395 234.152 175.595C233.192 174.768 232.019 174.355 230.632 174.355Z" fill="#3375BB"/>
            <path d="M257.427 190.435C256.813 190.888 256.053 191.235 255.147 191.475C254.267 191.688 253.333 191.795 252.347 191.795C249.787 191.795 247.8 191.141 246.387 189.835C245 188.528 244.307 186.608 244.307 184.075V175.235H240.987V170.435H244.307V165.195H250.547V170.435H255.907V175.235H250.547V183.995C250.547 184.901 250.773 185.608 251.227 186.115C251.707 186.595 252.373 186.835 253.227 186.835C254.213 186.835 255.053 186.568 255.747 186.035L257.427 190.435Z" fill="#3375BB"/>
            <path d="M144.065 47.6619C158.44 59.6676 174.925 58.9272 179.635 58.9272C178.605 127.207 170.755 113.667 144.065 132.813C117.375 113.667 109.574 127.207 108.543 58.9272C113.204 58.9272 129.689 59.6676 144.065 47.6619Z" stroke="#3375BB" strokeWidth="9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function MetamaskVerticalSvg() {
    return (
        <svg className="metamask-vertical-svg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 458.5 367.2" style={{'enableBackground':'new 0 0 458.5 367.2'}}>
<g id="bg_x28_do_not_export_x29_" className="st0">
</g>
<g id="MM_Head_background__x28_Do_not_edit_x29_">
	<path className="st1" d="M299.3,73.5L250,91.7l0,0l0,0h-41l0,0l-49.2-18.3l0.3,0.2l-0.3-0.2l-7.7,22.8l5.1,24.6l-3.3,2.4l4.9,3.7
		l-3.7,2.8l4.8,4.4l-3,2.2l7,8.1l-10.6,32.6h0l0,0l9.8,33.3l34.4-9.4l0,0l0,0v0l0,0l0,0v0l0,0l0,0l0,0l0,0l6.7,5.4l13.6,9.3h23.3
		l13.6-9.3l6.6-5.5l0,0v0l0,0v0l0,0l0,0l0,0v0l0-0.1l0,0.1l34.4,9.4l9.9-33.3l0,0h0l-10.6-32.6 M295.1,144.5l7-8.1l-3-2.2l4.8-4.4
		l-3.7-2.8l4.8-3.7l-3.2-2.4l5.1-24.6l-7.7-22.8"/>
</g>
<g id="Logos">
	<g>
		<g>
			<polygon className="st2" points="299.3,73.5 238.8,117.9 250,91.7 			"/>
			<polygon className="st2" points="159.8,73.5 219.7,118.3 209,91.7 			"/>
			<polygon className="st2" points="277.5,176.6 261.4,201 295.8,210.4 305.7,177.1 			"/>
			<polygon className="st2" points="153.4,177.1 163.2,210.4 197.6,201 181.6,176.6 			"/>
			<polygon className="st2" points="195.7,135.3 186.2,149.7 220.3,151.2 219.1,114.7 			"/>
			<polygon className="st2" points="263.3,135.3 239.6,114.3 238.8,151.2 272.9,149.7 			"/>
			<polygon className="st2" points="197.6,201 218.2,191.1 200.5,177.3 			"/>
			<polygon className="st2" points="240.8,191.1 261.4,201 258.6,177.3 			"/>
			<polygon className="st3" points="261.4,201 240.8,191.1 242.5,204.4 242.3,210 			"/>
			<polygon className="st3" points="197.6,201 216.7,210 216.6,204.4 218.2,191.1 			"/>
			<polygon className="st4" points="217.1,168.6 200,163.6 212.1,158.1 			"/>
			<polygon className="st4" points="242,168.6 247,158.1 259.1,163.6 			"/>
			<polygon className="st5" points="197.6,201 200.6,176.6 181.6,177.1 			"/>
			<polygon className="st5" points="258.5,176.6 261.4,201 277.5,177.1 			"/>
			<polygon className="st5" points="272.9,149.7 238.8,151.2 242,168.6 247,158.1 259.1,163.6 			"/>
			<polygon className="st5" points="200,163.6 212.1,158.1 217.1,168.6 220.3,151.2 186.2,149.7 			"/>
			<polygon className="st2" points="186.2,149.7 200.5,177.3 200,163.6 			"/>
			<polygon className="st2" points="259.1,163.6 258.6,177.3 272.9,149.7 			"/>
			<polygon className="st2" points="220.3,151.2 217.1,168.6 221.1,189.1 222,162 			"/>
			<polygon className="st2" points="238.8,151.2 237.1,162 238,189.1 242,168.6 			"/>
			<polygon className="st1" points="242,168.6 238,189.1 240.8,191.1 258.6,177.3 259.1,163.6 			"/>
			<polygon className="st1" points="200,163.6 200.5,177.3 218.2,191.1 221.1,189.1 217.1,168.6 			"/>
			<polygon className="st6" points="242.3,210 242.5,204.4 241,203.1 218.1,203.1 216.6,204.4 216.7,210 197.6,201 204.3,206.5 
				217.9,215.8 241.1,215.8 254.8,206.5 261.4,201 			"/>
			<polygon className="st4" points="240.8,191.1 238,189.1 221.1,189.1 218.2,191.1 216.6,204.4 218.1,203.1 241,203.1 242.5,204.4 			
				"/>
			<polygon className="st7" points="301.8,120.8 306.9,96.3 299.3,73.5 240.8,116.4 263.3,135.3 295.1,144.5 302.1,136.4 299,134.2 
				303.9,129.8 300.2,126.9 305,123.3 			"/>
			<polygon className="st7" points="152.1,96.3 157.3,120.8 154,123.3 158.9,126.9 155.2,129.8 160,134.2 157,136.4 164,144.5 
				195.7,135.3 218.2,116.4 159.8,73.5 			"/>
			<polygon className="st1" points="295.1,144.5 263.3,135.3 272.9,149.7 258.6,177.3 277.5,177.1 305.7,177.1 			"/>
			<polygon className="st1" points="195.7,135.3 164,144.5 153.4,177.1 181.6,177.1 200.5,177.3 186.2,149.7 			"/>
			<polygon className="st1" points="238.8,151.2 240.8,116.4 250,91.7 209,91.7 218.2,116.4 220.3,151.2 221,162.1 221.1,189.1 
				238,189.1 238,162.1 			"/>
		</g>
		<g>
			<path className="st8" d="M334.9,274.4c-2.2-1.5-4.7-2.5-7-3.8c-1.5-0.9-3.1-1.6-4.4-2.7c-2.2-1.8-1.8-5.4,0.6-7
				c3.3-2.2,8.9-1,9.5,3.6c0,0.1,0.1,0.2,0.2,0.2h5.1c0.1,0,0.2-0.1,0.2-0.2c-0.3-3.2-1.5-5.8-3.7-7.5c-2.1-1.6-4.6-2.5-7.2-2.5
				c-13.4,0-14.6,14.1-7.4,18.6c0.8,0.5,7.9,4.1,10.4,5.6c2.5,1.5,3.3,4.4,2.2,6.6c-1,2-3.5,3.4-6.1,3.3c-2.8-0.2-5-1.7-5.7-4
				c-0.1-0.4-0.2-1.2-0.2-1.6c0-0.1-0.1-0.2-0.2-0.2h-5.5c-0.1,0-0.2,0.1-0.2,0.2c0,4,1,6.2,3.7,8.2c2.5,1.9,5.3,2.7,8.1,2.7
				c7.5,0,11.4-4.2,12.1-8.6C340,280.9,338.7,277,334.9,274.4z"/>
			<path className="st8" d="M100,255.2h-8.3c-0.1,0-0.2,0.1-0.2,0.1L87,270.1c-0.1,0.2-0.3,0.2-0.4,0l-4.5-14.8c0-0.1-0.1-0.1-0.2-0.1
				h-8.4c-0.1,0-0.2,0.1-0.2,0.2v37.9c0,0.1,0.1,0.2,0.2,0.2h5.5c0.1,0,0.2-0.1,0.2-0.2v-28.8c0-0.2,0.3-0.3,0.4-0.1l4.5,14.9
				l0.3,1.1c0,0.1,0.1,0.1,0.2,0.1h4.2c0.1,0,0.2-0.1,0.2-0.1l0.3-1.1l4.5-14.9c0.1-0.2,0.4-0.2,0.4,0.1v28.8c0,0.1,0.1,0.2,0.2,0.2
				h5.5c0.1,0,0.2-0.1,0.2-0.2v-37.9C100.2,255.3,100.1,255.2,100,255.2z"/>
			<path className="st8" d="M250.9,255.2c-0.1,0-0.2,0.1-0.2,0.1l-4.5,14.8c-0.1,0.2-0.3,0.2-0.4,0l-4.5-14.8c0-0.1-0.1-0.1-0.2-0.1
				h-8.3c-0.1,0-0.2,0.1-0.2,0.2v37.9c0,0.1,0.1,0.2,0.2,0.2h5.5c0.1,0,0.2-0.1,0.2-0.2v-28.8c0-0.2,0.3-0.3,0.4-0.1l4.5,14.9
				l0.3,1.1c0,0.1,0.1,0.1,0.2,0.1h4.2c0.1,0,0.2-0.1,0.2-0.1l0.3-1.1l4.5-14.9c0.1-0.2,0.4-0.2,0.4,0.1v28.8c0,0.1,0.1,0.2,0.2,0.2
				h5.5c0.1,0,0.2-0.1,0.2-0.2v-37.9c0-0.1-0.1-0.2-0.2-0.2H250.9z"/>
			<path className="st8" d="M180.2,255.2h-25.9c-0.1,0-0.2,0.1-0.2,0.2v4.7c0,0.1,0.1,0.2,0.2,0.2h10v33c0,0.1,0.1,0.2,0.2,0.2h5.5
				c0.1,0,0.2-0.1,0.2-0.2v-33h10c0.1,0,0.2-0.1,0.2-0.2v-4.7C180.4,255.3,180.3,255.2,180.2,255.2z"/>
			<path className="st8" d="M199.4,276.7l4-14.8c0.1-0.2,0.3-0.2,0.4,0l4,14.8c0,0.1-0.1,0.3-0.2,0.3h-8
				C199.5,277,199.4,276.8,199.4,276.7z M212.5,293.5h5c0.1,0,0.2-0.1,0.2-0.3l-10.3-38c0-0.1-0.1-0.1-0.2-0.1h-7.2
				c-0.1,0-0.2,0.1-0.2,0.1l-10.3,38c0,0.1,0.1,0.3,0.2,0.3h5c0.1,0,0.2-0.1,0.2-0.1l3-11.1c0-0.1,0.1-0.1,0.2-0.1h11
				c0.1,0,0.2,0.1,0.2,0.1l3,11.1C212.4,293.4,212.5,293.5,212.5,293.5z"/>
			<path className="st8" d="M284.5,276.7l4-14.8c0.1-0.2,0.3-0.2,0.4,0l4,14.8c0,0.1-0.1,0.3-0.2,0.3h-8
				C284.6,277,284.5,276.8,284.5,276.7z M297.6,293.5h5c0.1,0,0.2-0.1,0.2-0.3l-10.3-38c0-0.1-0.1-0.1-0.2-0.1h-7.2
				c-0.1,0-0.2,0.1-0.2,0.1l-10.3,38c0,0.1,0.1,0.3,0.2,0.3h5c0.1,0,0.2-0.1,0.2-0.1l3-11.1c0-0.1,0.1-0.1,0.2-0.1h11
				c0.1,0,0.2,0.1,0.2,0.1l3,11.1C297.5,293.4,297.5,293.5,297.6,293.5z"/>
			<path className="st8" d="M122.5,287.9v-11.8c0-0.1,0.1-0.2,0.2-0.2h14.6c0.1,0,0.2-0.1,0.2-0.2V271c0-0.1-0.1-0.2-0.2-0.2h-14.6
				c-0.1,0-0.2-0.1-0.2-0.2v-10c0-0.1,0.1-0.2,0.2-0.2h16.6c0.1,0,0.2-0.1,0.2-0.2v-4.7c0-0.1-0.1-0.2-0.2-0.2h-22.5
				c-0.1,0-0.2,0.1-0.2,0.2v37.9c0,0.1,0.1,0.2,0.2,0.2H140c0.1,0,0.2-0.1,0.2-0.2v-5c0-0.1-0.1-0.2-0.2-0.2h-17.3
				C122.6,288.1,122.5,288,122.5,287.9z"/>
			<path className="st8" d="M385,293.1l-19-19.6c-0.1-0.1-0.1-0.2,0-0.3l17.1-17.7c0.1-0.1,0-0.3-0.1-0.3h-7c-0.1,0-0.1,0-0.1,0.1
				l-14.5,15c-0.1,0.1-0.3,0-0.3-0.1v-14.8c0-0.1-0.1-0.2-0.2-0.2h-5.5c-0.1,0-0.2,0.1-0.2,0.2v37.9c0,0.1,0.1,0.2,0.2,0.2h5.5
				c0.1,0,0.2-0.1,0.2-0.2v-16.7c0-0.2,0.2-0.3,0.3-0.1l16.4,16.9c0,0,0.1,0.1,0.1,0.1h7C385,293.5,385.1,293.2,385,293.1z"/>
		</g>
	</g>
</g>
</svg>
    )
}

export function DappsOverflow(params) {
    return (
        <div className="dapps-overflow">
            {params.children}
        </div>
    );
}

export function DappsTapThrough(params) {
    const { content } = params;

    const [index, setIndex] = useState(0);

    const hasPrev = index > 0;
    const hasNext = content.length && index < content.length - 1;

    return (
        <div className="dapps-tap-through">
            <div className="content">
                {content[index]}
            </div>
            <div className="controls">
                {<DappsButton onClick={prev} disabled={!hasPrev}>Prev</DappsButton>}
                {<DappsButton onClick={next} disabled={!hasNext}>Next</DappsButton>}
            </div>
            <div className="pages">{index + 1} / {content.length}</div>
        </div>
    )

    function prev() {
        setIndex(index - 1);
    }

    function next() {
        setIndex(index + 1);
    }
}


const mainnetData = {
    chainId: '0x9F2A4',                   // Chain ID (hexadecimal)
    chainName: 'ALL Mainnet',            // Network name
    nativeCurrency: {
        name: 'ALL Coin',                 // Native currency name
        symbol: 'ALL',                    // Native currency symbol
        decimals: 18,                      // Native currency decimals
        image: 'https://raw.githubusercontent.com/lloydgalley/Network/master/Alltra%20Logo.png',
    },
    rpcUrls: ['https://mainnet-rpc.alltra.global'], // RPC URL(s)
    blockExplorerUrls: ['https://alltra.global'], // Block explorer URL(s)
    iconUrls: ['https://raw.githubusercontent.com/lloydgalley/Network/master/Alltra%20Logo.png'],
};


const goldData = {
    address: '0xB6a67342C40326E41BE964D0DF4D042D1fBfdf49',
    symbol: '11::11',
    name: '11::11',
    decimals: 18,
    image: 'https://github.com/lloydgalley/Network/blob/master/11-11%20128x128.png?raw=true',
};

const hybxData = {
    address: '0x1839f77eBed7F388c7035f7061B4B8Ef0E72317a',
    symbol: 'HYBX',
    name: 'HYBX',
    decimals: 8,
    image: 'https://raw.githubusercontent.com/lloydgalley/Network/master/HYBX-logo128x128.png',
};

export function DappsWalletButton(params) {
    const account = getAccount();
    const network = getNetwork();

    return (
        <div className="dapps-wallet-button" onClick={() => showWallet(account)}>
            <FontAwesomeIcon icon={faWallet}/>
        </div>
    )

    async function showWallet(account) {
        const { address } = account;

        if(!account.isConnected) {
            notifier.msg("You must connect your wallet to use this feature");
            return;
        }

        const [allBalance, goldBalance, hybxBalance] = await Promise.all([
            fetchBalance({ address }).then(data => Number(data.value) / 10**18),
            readContract({ address: goldAddress, abi: erc20ABI, functionName: 'balanceOf', args: [address]}).then(v => Number(v) / 10**18),
            readContract({ address: allHybxAddress, abi: erc20ABI, functionName: 'balanceOf', args: [address]}).then(v => Number(v) / 10**8)
        ]);

        console.log([allBalance, goldBalance, hybxBalance])

        const quote = quoter.currentQuote;

        const { ALL_ETH, ETH_AUD, HYBX_ETH, XAU_USD, ETH_USD } = quote;
        // console.log({ ALL_ETH, ETH_AUD, HYBX_ETH, XAU_USD, ETH_USD })

        const allValue = allBalance * ALL_ETH * ETH_AUD;
        const goldValue = goldBalance / 1000 * XAU_USD / ETH_USD * ETH_AUD;
        const hybxValue = hybxBalance * HYBX_ETH * ETH_AUD;

        const allImgSrc = '/assets/alltra-logo-trimmed.png';
        const goldImgSrc = 'https://github.com/lloydgalley/Network/blob/master/11-11%20128x128.png?raw=true';
        const hybxImgSrc = 'https://raw.githubusercontent.com/lloydgalley/Network/master/HYBX-logo128x128.png';

        const tokens = [
            { imgSrc: allImgSrc, tokenBalance: allBalance, tokenSymbol: 'ALL', valueBalance: allValue, valueSymbol: 'AUD' },
            { imgSrc: goldImgSrc, tokenBalance: goldBalance, tokenSymbol: '11::11', valueBalance: goldValue, valueSymbol: 'AUD' },
            { imgSrc: hybxImgSrc, tokenBalance: hybxBalance, tokenSymbol: 'HYBX', valueBalance: hybxValue, valueSymbol: 'AUD' }
        ];

        overlay.show(<DappsWallet {...{ address, tokens}}/>)
        return;
    }
}

let counter = 0;
export function DappsWallet(props) {
    const { address, tokens } = props;
    
    return (
        <div className="dapps-wallet">
            <h2>Alltra Assets for</h2>
            <TruncatedAddress {...{address}}/>
            { tokens.map(t => <TokenVisualizer key={counter++} {...t}/>) }
            {/* <div>ALL: {allBalance}</div>
            <div>ALL: ${allValue} USD</div>
            <div>11::11: {goldBalance}</div>
            <div>11::11: ${goldValue} USD</div>
            <div>HYBX: {hybxBalance}</div>
            <div>HYBX: ${hybxValue} USD</div>
            <TokenVisualizer imgSrc={mainnetData.nativeCurrency.image} tokenBalance={allBalance} tokenSymbol={'ALL'} valueBalance={allValue} valueSymbol={'USD'}/>
            <TokenVisualizer imgSrc={'https://github.com/lloydgalley/Network/blob/master/11-11%20128x128.png?raw=true'} tokenBalance={goldBalance} tokenSymbol={'11::11'} valueBalance={goldValue} valueSymbol={'USD'}/>
            <TokenVisualizer imgSrc={'https://raw.githubusercontent.com/lloydgalley/Network/master/HYBX-logo128x128.png'} tokenBalance={hybxBalance} tokenSymbol={'HYBX'} valueBalance={hybxValue} valueSymbol={'USD'}/> */}
        </div>
    );
}

export function TokenVisualizer(props) {
    const { imgSrc, tokenBalance, tokenSymbol, valueBalance, valueSymbol } = props;

    return (
        <div className="token-visualizer">
            <img src={imgSrc}/>
            
            {/* <div>
                <div className="flex1"/>
                <div>&nbsp;</div>
                <div className="flex1"/>
                <div>$</div>
                <div className="flex1"/>
            </div> */}
            <div className="flex1"></div>
            <div>
                <div className="flex1"/>
                <div>&nbsp;{round(tokenBalance, 2)}</div>
                <div className="flex1"/>
                <div>&nbsp;${round(valueBalance, 2)}</div>
                <div className="flex1"/>
            </div>
            <div>&nbsp;&nbsp;</div>
            <div>
                <div className="flex1"/>
                <div><b>{tokenSymbol}</b></div>
                <div className="flex1"/>
                <div><b>{valueSymbol}</b></div>
                <div className="flex1"/>
            </div>
        </div>
    )

    function round(n, dp) {
        const rn = Math.round(n * 10**dp) / 10**dp;
        let [numeral, decimal] = rn.toString().split('.');
        
        let current = '';
        let triplets = [];
        for(let i=numeral.length-1; i>=0; --i) {
            current = numeral[i] + current;
            if(current.length === 3) {
                triplets.unshift(current);
                current = '';
            }
        }

        if(current.length) triplets.unshift(current);

        if(!decimal) decimal = '00';
        decimal = decimal.padEnd(dp, '0');

        return `${triplets.join(',')}.${decimal}`;
    }
}

export function TruncatedAddress({ address }) {
    const length = address.length;

    const prefix = address.slice(0, 6);
    const suffix = address.slice(length - 4, length);

    return (
        <h2 className="truncated-address">
            {prefix}...{suffix}
        </h2>
    )
}
