
import './overlay.scss';
import { createRoot } from 'react-dom/client';

export function createOverlay(rootElement) {
    const root = createRoot(rootElement);

    const state = {
        animation: 'hide',
    }

    return {
        root,
        rootElement,
        state,
        show,
        hide,
    };

    function show(content) {
        state.animation = 'show';
        state.content = content;
        render();
    }

    function hide() {
        state.animation = 'hide';
        
        setTimeout(displayNone, 250);
        render();
    }

    function displayNone() {
        if(state.animation = 'hide') {
            state.animation = 'display-none';
            render();
        }
    }

    function render() {
        root.render(
            <Overlay animation={state.animation} hide={hide}>{state.content}</Overlay>
        );
    }
}


//TODO equivalent js object ?
export function Overlay(props) {
    return (
        <div className="overlay" animation={props.animation}>
            <div className="overlay-background" onClick={props.hide}></div>
            <div className="overlay-content">{props.children}</div>
        </div>
    )
}

export const overlay = window.overlay = createOverlay(document.getElementById('overlay-root'));
