
import { QueryClient } from "@tanstack/react-query"; // this for some reason needs to be here

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal, Web3Button } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'

import { getNetwork, getAccount, switchNetwork} from '@wagmi/core';
import { allMainnet } from './custom-chains.js';


// import { configureChains, createConfig, } from '@wagmi/core'
// import { WagmiConfig } from 'wagmi';



export const projectId = '615ab311495a50fda90f3d80f1746f56';
export const chains = [allMainnet, mainnet];

//TODO ajax this mf


export const chainConfig = configureChains(chains, [w3mProvider({ projectId })]);

const { publicClient } = chainConfig;


export const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
});

export const ethereumClient = new EthereumClient(wagmiConfig, chains);
// // const web3modal = new Web3Modal({ projectId }, ethereumClient)
// export const web3modal = <Web3Modal {...{projectId, ethereumClient}} />
// const 

export function Modal() {
    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <Web3Button />
            </WagmiConfig>

            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
    )
};


export async function safeSwitchNetwork(chainId) {

    const network = getNetwork();
    if(network?.chain?.id !== chainId) { // ==?
        try {
            await switchNetwork({ chainId });
        } catch(e) {
            console.log(e);
        }
    }
    
}

export async function ensureConnected({ notifier, modal, targetNetwork }) {
    const id = targetNetwork.id;
    const account = getAccount();
    const network = getNetwork();

    if(!account.isConnected) {
        modal.open();
        notifier.msg('You must connect your wallet to use this app');
        return false;
    }

    if(network?.chain?.id !== id) {
        // console.log({network, targetNetwork});
        if(targetNetwork) {
            notifier.msg(`You must switch to the ${targetNetwork.name} network to perform this transaction`);
        }

        try {
            await safeSwitchNetwork(id);
        } catch(e) {
            console.log(e);
            return false;
        }
    }

    return true;
}


// window.projectId = projectId;
// window.chainConfig = chainConfig;
// window.wagmiConfig = wagmiConfig
// window.ethereumClient = ethereumClient;
