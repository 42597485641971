
//TODO implement the ability to cancel for the quoter
//TODO syncronous inspection of state and resolved values
export default function promish() {
    let resolve, reject;

    const p = new Promise((res, rej) => {
        [resolve, reject] = [res, rej];
    });

    [p.resolve, p.reject] = [resolve, reject];

    return p;
}
