
import './notifier.scss';
import { createRoot } from 'react-dom/client';

export function createNotifier(rootElement) {
    const root = createRoot(rootElement);

    let totalNotifications = 0;

    const state = {
        notifications: [],
    };

    return {
        root,
        rootElement,
        state,
        msg,
        render
    };

    function render() {
        const { notifications } = state;
        root.render(<Notifier {...{notifications}}/>);
    }

    function msg(msg) {
        const data = {
            key: totalNotifications,
            animation: 'show',
            onClick: closeNotification(totalNotifications),
            msg,
        };

        ++totalNotifications;

        state.notifications.push(data);
        render();
    }

    function closeNotification(key) {
        return (e) => {
            const n = state.notifications.find(n => n.key === key);
            n.animation = 'hide';
            
            setTimeout(() => removeNotification(n.key), 250);
            render();
        }
    }

    function removeNotification(key) {
        state.notifications = state.notifications.filter(n => n.key !== key);
        render();
    }
}

export function Notification({onClick, msg, animation}) {

    return (
        <div className="notification" {...{animation}}>
            <div className="close" {...{onClick}}>&times;</div>
            {/* <i class="fas fa-times"></i> */}
            {msg}
        </div>
    )
}

export function Notifier({ notifications }) {

    return (
        <div className="notifier">
            <div className="notification-spacer"></div>
            {notifications.map(data => <Notification {...data}/>)}
        </div>
    );
}

export const notifier = window.notifier = createNotifier(document.getElementById('notifier-root'));
